<template>
  <div>
    <v-col cols="12">
<v-btn @click="irRutas">Regresar a Rutas</v-btn>
      <base-card>
        <v-card-title>Ruta de {{cliente.nombre}}</v-card-title>
        <v-card-text>
          <v-col cols="4" offset="10">
            <v-btn color="success" @click="saveRuta">
              <v-icon>mdi-content-save-outline</v-icon>
              Guardar
            </v-btn>
          </v-col>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="ruta.nombre"
                label="Nombre de la ruta"
                class="uppercase"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="ruta.origen"
                label="Origen"
                class="uppercase"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="ruta.destino"
                label="Destino"
                class="uppercase"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </base-card>
    </v-col>
    <v-row>
      <v-col cols="4">
        <base-card>
          <v-col cols="2">
            <v-btn color="info" @click="addVia(caseta)">Caseta</v-btn>
          </v-col>
          <v-col
            cols="10"
            offset="1"
            v-for="(caseta, index_caseta) in ruta.lista_casetas"
            :key="index_caseta"
          >
            <v-row>
              <v-autocomplete
                v-model="caseta.idcaseta"
                :items="lista_vias"
                item-text="via"
                item-value="idcaseta"
                dense
                filled
                label="Vía"
              >
              </v-autocomplete>
              <v-btn
                color="danger"
                style="margin-left:15px"
                @click="deleteVia(index_caseta)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </base-card>
      </v-col>
      <v-col cols="8">
        <base-card>
        <v-col cols="1" offset="10">
        </v-col>
          <v-col cols="2">
            <v-btn color="info" @click="addCosto(costo)">Agregar Flete</v-btn>
          </v-col>
          <div             v-for="(costo, index_costo) in ruta.lista_costos"
>        <v-btn color="info" @click="calcularPeaje(costo,ruta)"  style="margin-left:15px;margin-top:15px"><v-icon>mdi-refresh</v-icon></v-btn>

          <v-row >
            <v-col cols="3" style="margin-left:15px">
              <v-select
              v-model="costo.idtipo"
                :items="lista_tipo_unidad"
                item-text="descripcion"
                item-value="idtipo"
                label="Tipo"
                @change="calcularPeaje(costo,ruta)"
              >
              </v-select>
            </v-col>
            <v-col cols="3">
              <v-select
              v-model="costo.idtipo_remolque"
                :items="lista_cajas"
                item-text="descripcion"
                item-value="idtipo_remolque"
                label="Caja"

              >
              </v-select>

            </v-col>
            <v-col cols="3" v-show="costo.doble">
                <v-select
                v-model="costo.idtipo_remolque2"
                :items="lista_cajas"
                item-text="descripcion"
                item-value="idtipo_remolque"
                label="Caja"
              @change="calcularPeaje(costo,ruta)"

              >
              </v-select>
            </v-col>
         
             <v-col cols="2">
               <v-checkbox v-show="costo.idtipo=='2'"
              @change="calcularPeaje(costo,ruta)"

            v-model="costo.doble"
            label="Segundo Remolque"
          />
            </v-col>


          </v-row>
<v-row>
     <v-col cols="2">
        <v-text-field
         label="Flete"
              v-model="costo.flete"
              style="margin-left:15px"
                type="nombre"
                class="form-control"
                placeholder="$ Flete"
              />
     </v-col>
          <v-col cols="2">

              <v-text-field
              v-model="costo.diesel"
               label="Diesel"
              style="margin-left:15px"
                type="nombre"
                class="form-control"
                placeholder="Lts Diesel"
              />
            </v-col>
             <v-col cols="3">
              <v-text-field
              v-model="costo.presupuesto_operador"
               label="Presup. Operador"
              style="margin-left:15px"
                type="nombre"
                class="form-control"
                placeholder="$ Presup. Operador"
              />
            </v-col>

</v-row>  
<v-row>
     <v-col cols="3">
         <label style="font-size:110%;margin-left:15px">Peajes:${{costo.peaje}}</label>

            </v-col>
			<v-btn color="danger" @click="deleteCosto(index_costo)">                <v-icon>mdi-delete</v-icon>
</v-btn>
         <!--  <v-col cols="3">
                   <label style="font-size:110%;margin-left:15px">Total:${{parseFloat(costo.flete)+parseFloat(costo.peaje)+parseFloat(costo.diesel)+parseFloat(costo.presupuesto_operador)}}</label>

            </v-col>-->
  </v-row>      
          </div>
        </base-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { url } from "../../main.js";

export default {
  data() {
    return {
		path:{
			text: 'Rutas',
        disabled: false,
        href: 'rutas'
		},
      idcliente: this.$route.params.idcliente,
      idruta: this.$route.params.idruta,

      idcaseta: 0,
	  lista_casetas_borrar:[],
	  lista_costos_borrar:[],
cliente:{},
      lista_tipo_unidad: [],
      lista_cajas: [],

      ruta: { 
        idcliente:this.$route.params.idcliente,
        lista_casetas:[],
		lista_casetas_borrar:[],
        lista_costos:[],
		lista_costos_borrar:[],
        origen: "", destino: "", doble:false},
      lista_vias: [],
      lista_ejes: [],
      lista_casetas: [],
      lista_costo_total: [],
      lista_vias_agregadas: [],
      eje: {},
      via: {},
      caseta: {},
      peaje: 0,
      costo:{idtipo:0,idtipo_remolque1:0,idtipo_remolque2:0,flete:'',peaje:0,diesel:'',presupuesto_operador:''},
      total_peaje: 0
    };
  },
  mounted() {
    this.verVias();
    this.verTipoUnidad();
    this.verCajas();
    this.verCliente();
    if(this.idruta!=0)
    {
       this.verRuta()
    }
    
  },

  methods: {
    verVias() {
      this.axios.get(url + "apiVias.php/casetas").then(respuesta => {
        this.lista_vias = respuesta.data;
      });
    },
    verTipoUnidad() {
      this.axios
        .get(url + "apiTractoCamiones.php/get_tipos")
        .then(respuesta => {
          this.lista_tipo_unidad = respuesta.data;
        })
        .catch(function() {});
    },
    verCajas() {
      this.axios
        .get(url + "apiRemolques.php/tipos_remolque")
        .then(respuesta => {
          this.lista_cajas = respuesta.data;


          //   this.remolque.idtipo_remolque = this.lista_tipos[0].idtipo_remolque;
        })
        .catch(function() {
          console.log("FAILURE ver!!");
        });
    },
    verCliente(){

  this.axios
        .get(url + "apiClientes.php/cliente/"+this.idcliente)
        .then(respuesta => {
          console.log(respuesta);
          this.cliente = respuesta.data;

          console.log(this.lista_cajas);

          //   this.remolque.idtipo_remolque = this.lista_tipos[0].idtipo_remolque;
        })
        .catch(function() {
          console.log("FAILURE ver!!");
        });
    },
    verEjes() {},
    addVia(caseta) {

      this.ruta.lista_casetas.push(Object.assign({},caseta));
    },
    addCosto(costo) {
   

      this.ruta.lista_costos.push(Object.assign({},costo));
    

    },

    calcularPeaje(costo,ruta) {
      console.log(costo)
var eje=0;
if(costo.idtipo==1)
{
  eje='tres_ejes';


}

if(costo.idtipo==2)
{
  eje='cinco_ejes';
}

if(costo.idtipo==2 && costo.doble)
{
    eje='nueve_ejes';

}
console.log(eje);

costo.peaje=0;
ruta.lista_casetas.forEach(element => {
   this.axios
        .get(url + "apiClientes.php/precio_via/"+eje+"/"+element.idcaseta)
        .then(respuesta => {
          costo.peaje+= parseFloat(respuesta.data);
                    console.log(costo.peaje);

        })
        .catch(function() {
          console.log("FAILURE ver!!");
        });

    
});


    },
    
    deleteVia(index) {
      // this.cliente.borrar_ruta.push(this.cliente.lista_rutas[index_ruta]);
	  this.lista_casetas_borrar.push(this.ruta.lista_casetas[index])
	  console.log( this.lista_casetas_borrar)
    this.ruta.lista_casetas.splice(index, 1);

    },
	deleteCosto(index)
	{
		console.log(this.ruta)

		this.lista_costos_borrar.push(this.ruta.lista_costos[index])

		this.ruta.lista_costos.splice(index,1);
	},
    saveRuta() {
		this.ruta.lista_costos_borrar=this.lista_costos_borrar;
		this.ruta.lista_casetas_borrar=this.lista_casetas_borrar;
		console.log(this.ruta);
   this.axios
        .post(url + "apiClientes.php/ruta",this.ruta)
        .then(respuesta => {
          console.log(respuesta);
          if (respuesta.data.status === 200) {
              this.$swal(respuesta.data.msg);
			  this.$router.push("/catalogos/rutas/"+idcliente);

            
            }
          
        })
        .catch(function() {
          console.log("FAILURE ver!!");
        });

    },
    verRuta()
    {

       this.axios
        .get(url + "apiClientes.php/ruta/"+this.idruta)
        .then(respuesta => {
          this.ruta=respuesta.data;

          this.ruta.lista_costos.forEach(element => {

            if(element.idtipo_remolque2>0)
            {
              element.doble=true;
            }
            
          });
         
        })
        .catch(function() {
          console.log("FAILURE ver!!");
        });
    
    },
	irRutas(){
		this.$router.push("/catalogos/rutas/"+this.idcliente);

	}
  }
};
</script>
